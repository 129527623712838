<template>
  <div
    id="kt_footer"
    class="footer bg-white py-4 d-flex flex-lg-column"
  >
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      :class="{
        'container-fluid': widthFluid,
        container: !widthFluid,
      }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted font-weight-bold mr-2">2021©</span>
        <a
          href="https://kelime.com"
          target="_blank"
          class="text-dark-75 text-hover-primary"
        >Kelime.com</a>
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="nav nav-dark order-1 order-md-2">
        <a
          href="https://kelime.com/hakkimizda"
          target="_blank"
          class="nav-link pr-3 pl-0"
        >Hakkımızda</a>
        <a
          href="https://kelime.com/iletisim"
          target="_blank"
          class="nav-link pl-3 pr-0"
        >İletişim</a>
      </div>
      <!--end::Nav-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'KTFooter',
  computed: {
    ...mapGetters(['layoutConfig']),

    /**
         * Check if subheader width is fluid
         */
    widthFluid() {
      return this.layoutConfig('footer.width') === 'fluid';
    },
  },
};
</script>
