var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Dashboard ")])])])]}}])}),_c('router-link',{attrs:{"to":"/maddeler","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Maddeler ")])])])]}}])}),_c('router-link',{attrs:{"to":"/gundemler","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Gündemler ")])])])]}}])}),_c('router-link',{attrs:{"to":"/users","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Kullanıcılar ")])])])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open",class:{ 'menu-item-active': _vm.hasActiveChildren() },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/kuluckasozluk","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Kuluçka Sözlüklükleri ")])])])]}}])}),_c('router-link',{attrs:{"to":"/kuluckamaddeler","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Kuluçka Maddeleri ")])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open",class:{ 'menu-item-active': _vm.hasActiveChildren() },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_vm._m(1),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/blog","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Blog ")])])])]}}])}),_c('router-link',{attrs:{"to":"/diller","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Diller ")])])])]}}])}),_c('router-link',{attrs:{"to":"/dictionary","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Sözlükler ")])])])]}}])}),_c('router-link',{attrs:{"to":"/packet","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Paketler ")])])])]}}])}),_c('router-link',{attrs:{"to":"/kurumlar","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Kurumlar ")])])])]}}])}),_c('router-link',{attrs:{"to":"/toplukullanici","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Toplu Kullanıcı Oluşturma ")])])])]}}])})],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Kuluçka ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Diğer ")])])
}]

export { render, staticRenderFns }